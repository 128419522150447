"use client";

import React from "react";
import dynamic from "next/dynamic";

const Background = dynamic(() => import("./Background"), { ssr: false });

const LazyBackground: React.FC = () => {
  return <Background />;
};

export default LazyBackground;
